<template>
  <div>
    <el-row>
      <el-col>
        <el-button type="primary" @click="showCreateUser = true">创建用户</el-button>
      </el-col>
    </el-row>
    <el-table :data="users" stripe style="width: 100%">
      <el-table-column prop="ID" label="ID" width="100"></el-table-column>
      <el-table-column prop="username" label="用户名" width="230"></el-table-column>
      <el-table-column prop="phoneNumber" label="手机号" width="400"></el-table-column>
      <el-table-column label="角色" width="100">
        <template #default="scope">
          {{ transRole(scope.row.role) }}
        </template>
      </el-table-column>
      <el-table-column prop="CreatedAt" label="创建时间" width="300"></el-table-column>
      <el-table-column label="操作" width="500">
        <template #default="scope">
          <el-button size="small" text type="primary" @click="editUser(scope.row)">重置密码</el-button>
          <el-button size="small" text type="danger" @click="deleteUser(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
        layout="total, prev, pager, next" :total="totalUsers"></el-pagination>
    </div>
    <el-dialog v-model="showCreateUser" title="创建用户" width="500">
      <el-form :model="createUserForm">
        <el-form-item label="用户名">
          <el-input v-model="createUserForm.username" />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="createUserForm.phoneNumber" />
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="createUserForm.role" placeholder="请选择">
            <el-option label="管理员" value="admin" />
            <el-option label="普通用户" value="user" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showCreateUser = false">取消</el-button>
          <el-button type="primary" @click="createUser">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="showDeleteUser" title="确认删除" width="500">
      <span>确认删除用户 {{ this.delUser.username }} 吗？</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showDeleteUser = false">取消</el-button>
          <el-button type="danger" @click="confirmDeleteUser()">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="showResetPassword" title="确认重置密码" width="500">
      <span>确认重置用户 {{ this.resetPwdUser.username }} 的密码吗？</span>
      <span>新密码为: Aa123456</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showResetPassword = false">取消</el-button>
          <el-button type="primary" @click="confirmResetPassword()">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserManagement',
  data() {
    return {
      createUserForm: {
        username: '',
        phoneNumber: '',
        role: ''
      },
      showCreateUser: false,
      showDeleteUser: false,
      showResetPassword: false,
      users: [],
      currentPage: 1,
      pageSize: 10,
      totalUsers: 0,
      delUser: {},
      resetPwdUser: {}
    };
  },
  methods: {
    fetchUsers() {
      axios
        .get('/api/v1/users', {
          params: {
            page: this.currentPage,
            limit: this.pageSize,
          },
        })
        .then((response) => {
          this.users = response.data.data;
          this.totalUsers = response.data.total;
        })
        .catch((error) => {
          this.$message.error('Failed to fetch users: ' + error.response.data.error);
        });
    },
    createUser() {
      axios
        .post('/auth/create-user',
          this.createUserForm,
        )
        .then((response) => {
          this.$message.success('create users success: ' + response.data.message);
          this.fetchUsers()
          this.showCreateUser = false
        })
        .catch((error) => {
          this.$message.error('Failed to create users: ' + error.response.data.error);
        });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchUsers();
    },
    editUser(user) {
      // Implement edit user logic
      this.resetPwdUser = user
      this.showResetPassword = true
    },
    deleteUser(user) {
      // Implement delete user logic
      this.delUser = user
      this.showDeleteUser = true
    },
    confirmDeleteUser() {
      axios
        .delete('/api/v1/users/' + this.delUser.ID)
        .then((response) => {
          this.$message.success('delete user success: ' + response.data.message);
          this.fetchUsers()
          this.showDeleteUser = false
        })
        .catch((error) => {
          this.$message.error('Failed to delete user: ' + error.response.data.error);
        });
    },
    confirmResetPassword() {
      axios
        .put('/api/v1/users/' + this.resetPwdUser.ID + '/reset-password')
        .then((response) => {
          this.$message.success('reset password success: ' + response.data.message);
          this.fetchUsers()
          this.showResetPassword = false
        })
        .catch((error) => {
          this.$message.error('Failed to reset password : ' + error.response.data.error);
        });
    },
    transRole(role){
      if(role == 'admin') {
        return "管理员"
      }
      return "普通用户"
    }
  },
  created() {
    this.fetchUsers();
  },
};
</script>

<style>
.user-management-container {
  padding: 20px;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}

.el-form-item__label {
  color: black !important;
}
</style>
