import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn';

const app = createApp(App);

// 设置全局 axios 默认值
axios.defaults.baseURL = 'http://47.113.184.75/api';
// axios.defaults.baseURL = 'http://127.0.0.1:8080';

// 设置请求拦截器以添加 JWT 令牌到请求头
axios.interceptors.request.use(config => {
  const token = store.state.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

app.use(router);
app.use(store);
app.use(ElementPlus, {
  locale,
});
app.mount('#app');
