<template>
  <div>
    <h2>Create Course</h2>
    <form @submit.prevent="createCourse">
      <div>
        <label>Title</label>
        <input v-model="title" type="text" required />
      </div>
      <div>
        <label>Description</label>
        <input v-model="description" type="text" />
      </div>
      <div>
        <label>Channel ID</label>
        <input v-model="channelID" type="number" required />
      </div>
      <button type="submit">Create</button>
    </form>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      title: '',
      description: '',
      channelID: ''
    };
  },
  methods: {
    createCourse() {
      axios.post('/api/courses', {
        title: this.title,
        description: this.description,
        channel_id: this.channelID
      }).then(() => {
        alert('Course created successfully');
        this.title = '';
        this.description = '';
        this.channelID = '';
      }).catch(error => {
        alert('Error creating course: ' + error.response.data.error);
      });
    }
  }
};
</script>
  
<style>
/* 添加样式 */
</style>
  