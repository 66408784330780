<template>
  <div>
    <h2>Change Password</h2>
    <el-form @submit.prevent="changePassword">
      <el-form-item label="旧密码">
        <el-input v-model="oldPassword" type="password" required></el-input>
      </el-form-item>
      <el-form-item label="新密码">
        <el-input v-model="newPassword" type="password" required></el-input>
      </el-form-item>
      <el-form-item label="确认新密码">
        <el-input v-model="checkPassword" type="password" required></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="changePassword">修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      checkPassword: ''
    };
  },
  methods: {
    changePassword() {
      if (this.newPassword != this.checkPassword) {
        this.$message.error('新旧密码不一致，请重新输入')
        return
      }
      axios.post('/auth/change-password', {
        old_password: this.oldPassword,
        new_password: this.newPassword
      }).then(() => {
        this.$message.success('修改密码成功')
        this.oldPassword = '';
        this.newPassword = '';
        this.$store.dispatch('logout').then(() => {
          this.$router.push('/login');
        });
      }).catch(error => {
        this.$message.error('修改密码失败: ' + error)
      });
    }
  }
};
</script>
  
<style>
/* 添加样式 */
</style>
  