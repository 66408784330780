<template>
  <div class="order-data-container">
    <div class="filter-section">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="时间范围">
            <el-date-picker v-model="filters.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" format="YYYY.MM.DD" value-format="YYYY.MM.DD"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4" v-show="isAdmin">
          <el-form-item label="子账号">
            <el-select v-model="filters.username" clearable placeholder="请选择" @change="handleSelectUser">
              <el-option v-for="user in allUsers" :key="user.username" :label="user.username"
                :value="user.username"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="课程">
            <el-select v-model="filters.courseName" clearable placeholder="请选择" @change="handleSelectCourse">
              <el-option v-for="course in courses" :key="course.name" :label="course.name"
                :value="course.name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="推广位">
            <el-select v-model="filters.channelName" clearable placeholder="请选择">
              <el-option v-for="channel in channels" :key="channel.name" :label="channel.name"
                :value="channel.name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="filterOrders">查询</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-upload v-if="isAdmin" accept=".xlsx, .xls" v-model:file-list="fileList" class="upload-demo"
            :auto-upload="false" :limit="1">
            <template #trigger>
              <el-button type="primary">选择文件</el-button>
            </template>
            <el-button class="ml-3" text type="primary" @click="submitUpload">
              确认上传
            </el-button>
            <template #tip>
              <div class="el-upload__tip">
                请选择excel/csv文件
              </div>
            </template>
          </el-upload>
        </el-col>
      </el-row>
    </div>
    <el-divider />
    <div class="order-cards">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-card class="data-card">
            <template #header>
              <div class="clearfix">
                <span>出单量</span>
              </div>
            </template>
            <div>
              <p>{{ saleNum }}</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
  data() {
    return {
      filters: {
        dateRange: [],
        username: '',
        channelName: '',
        courseName: ''
      },
      channels: [],
      courses: [],
      saleNum: 0,
      fileList: [],
      allUsers: []
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    handleExceed(file, uploadFiles) {
      console.log(file)
      console.log(uploadFiles)
      uploadFiles.clear
      uploadFiles = [].push(file)
      console.log(uploadFiles)
    },
    readFile(file) {
      return new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = ev => {
          resolve(ev.target.result)
        }
      })
    },
    async submitUpload() {
      console.log(this.fileList)
      let file = this.fileList[0].raw
      console.log(file)
      if (!file) {
        this.$message.error("文件打开失败")
        return
      } else {
        let data = await this.readFile(file)
        let workbook = XLSX.read(data, { type: "binary" })
        console.log(workbook)
        let worksheet = workbook.Sheets[workbook.SheetNames[0]]
        let result = XLSX.utils.sheet_to_json(worksheet);
        console.log(result)
        let sales = []
        for (let r of result) {
          sales.push({
            input_date: r.日期,
            sale_num: r.出单量,
            username: r.子账号,
            channel_name: r.推广位,
            course_name: r.课程类别
          })
        }
        console.log(sales)
        axios
          .post('/api/v1/sales',
            sales,
          )
          .then(() => {
            this.$message.success('上传成功');
            this.filterOrders()
          })
          .catch((error) => {
            this.$message.error('上传失败: ' + error.response.data.error);
          }).finally(() => {
            this.fileList = []
          });
      }
    },
    fetchFiltersData() {
      // Fetch channels and courses from the API
      // axios.get('/api/v1/all/channels').then(response => {
      //   this.channels = response.data.data;
      // });
      // 管理员从子账号开始筛选
      if (this.isAdmin == true) {
        axios.get('/api/v1/all/users').then(response => {
          this.allUsers = response.data.data;
        });
        return
      }
      // 普通用户从课程开始筛选
      axios.get('/api/v1/all/courses').then(response => {
        this.courses = response.data.data;
      });
    },
    filterOrders() {
      console.log(this.filters)
      // Apply filters to fetch order count
      let url = '/api/v1/get-sales'
      if (this.isAdmin) {
        url = url + `?targetUser=${this.filters.username}`
      }
      axios
        .post(url,
          this.filters,
        )
        .then((response) => {
          this.saleNum = response.data.data
          console.log(response)
        })
        .catch((error) => {
          this.$message.error('Failed to create sales: ' + error.response.data.error);
        });
    },
    handleSelectUser(e) {
      for (let u of this.allUsers) {
        if (u.username == e) {
          let that = this
          axios.get(`/api/v1/all/courses?targetUser=${u.username}`).then(response => {
            that.courses = response.data.data;
            that.filters.courseName = ''
            that.filters.channelName = ''
          });
        }
      }
    },
    handleSelectCourse(e) {
      for (let c of this.courses) {
        if (c.name == e) {
          let that = this
          let url = `/api/v1/all/channels?course=${c.name}`
          if (this.isAdmin) {
            url = url + `&targetUser=${that.filters.username}`
          }
          axios.get(url).then(response => {
            that.channels = response.data.data;
            that.filters.channelName = ''
          });
        }
      }
      console.log(e)
    }
  },
  created() {
    this.fetchFiltersData();
  }
};
</script>

<style>
.order-data-container {
  padding: 20px;
}

.filter-section {
  margin-bottom: 20px;
}

.order-cards {
  display: flex;
  justify-content: space-between;
}

.el-card {
  width: 100%;
}

.el-row {
  display: flex;
  flex-wrap: wrap;
}

.el-col {
  flex: 1;
}

.data-card {
  width: 300px;
  height: 400px;
}
</style>
