<template>
    <div class="order-data-container">
        <div class="filter-section">
            <el-table :data="courses" stripe style="width: 100%">
                <el-table-column type="index" label="序号" width="200"></el-table-column>
                <el-table-column prop="username" label="用户名" width="350"></el-table-column>
                <el-table-column prop="name" label="课程名" width="400"></el-table-column>
                <el-table-column prop="CreatedAt" label="创建时间" width="350"></el-table-column>
                <el-table-column label="操作" width="300">
                    <template #default="scope">
                        <el-button size="small" text type="primary" @click="editCourse(scope.row)">修改</el-button>
                        <el-button size="small" text type="danger" @click="deleteCourse(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
                    layout="total, prev, pager, next" :total="totalCourses"></el-pagination>
            </div>
            <el-dialog v-model="showEditCourse" title="修改课程" width="500">
                <el-form :model="updateCourseFrom">
                    <el-form-item label="课程名">
                        <el-input v-model="updateCourseFrom.name" />
                    </el-form-item>
                </el-form>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button @click="showEditCourse = false">取消</el-button>
                        <el-button type="primary" @click="confirmEditCourse">
                            确认
                        </el-button>
                    </div>
                </template>
            </el-dialog>
            <el-dialog v-model="showDeleteCourse" title="确认删除" width="500">
                <span>确认要删除课程 {{ this.delCourse.name }} 吗？</span>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button @click="showDeleteCourse = false">取消</el-button>
                        <el-button type="primary" @click="confirmDeleteCourse()">
                            确认
                        </el-button>
                    </div>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            updateCourseFrom: {
                name: ''
            },
            courses: [],
            currentPage: 1,
            pageSize: 10,
            totalCourses: 0,
            delCourse: {},
            eCourse: {},
            showEditCourse: false,
            showDeleteCourse: false,
        };
    },
    methods: {
        fetchCourses() {
            axios.get('/api/v1/courses', {
                params: {
                    page: this.currentPage,
                    limit: this.pageSize,
                },
            }).then((response) => {
                this.courses = response.data.data;
                this.totalCourses = response.data.total;
            }).catch((error) => {
                this.$message.error('Failed to fetch courses: ' + error.response.data.error);
            });
        },
        handleCurrentChange(page) {
            this.currentPage = page;
            this.fetchCourses();
        },
        editCourse(course) {
            this.eCourse = course
            this.updateCourseFrom.name = course.name
            this.showEditCourse = true
        },
        deleteCourse(course) {
            this.delCourse = course
            this.showDeleteCourse = true
        },
        confirmEditCourse() {
            let that = this
            axios.put('/api/v1/courses',
                {
                    username: that.eCourse.username,
                    oldName: that.eCourse.name,
                    newName: that.updateCourseFrom.name
                }
            ).then((response) => {
                that.$message.success('update course success: ' + response.data.message);
                that.fetchCourses()
                that.showEditCourse = false
            }).catch((error) => {
                that.$message.error('Failed to update course: ' + error.response.data.error);
            });
        },
        confirmDeleteCourse() {
            let that = this
            let url = `/api/v1/courses/${that.delCourse.name}/users/${that.delCourse.username}`
            axios.delete(url,
                {
                    username: that.delCourse.username,
                    name: that.delCourse.name,
                }
            ).then((response) => {
                that.$message.success('delete course success: ' + response.data.message);
                that.fetchCourses()
                that.showDeleteCourse = false
            }).catch((error) => {
                that.$message.error('Failed to delete course: ' + error.response.data.error);
            });
        }
    },
    created() {
        this.fetchCourses();
    },
};
</script>

<style>
.order-data-container {
    padding: 20px;
}

.filter-section {
    margin-bottom: 20px;
}

.order-cards {
    display: flex;
    justify-content: space-between;
}

.el-card {
    width: 100%;
}

.el-row {
    display: flex;
    flex-wrap: wrap;
}

.el-col {
    flex: 1;
}

.data-card {
    width: 300px;
    height: 400px;
}
</style>