<template>
  <div class="order-data-container">
    <div class="filter-section">
      <el-table :data="Channels" stripe style="width: 100%">
        <el-table-column type="index" label="序号" width="200"></el-table-column>
        <el-table-column prop="username" label="用户名" width="200"></el-table-column>
        <el-table-column prop="courseName" label="课程名" width="300"></el-table-column>
        <el-table-column prop="name" label="推广位" width="300"></el-table-column>
        <el-table-column prop="CreatedAt" label="创建时间" width="300"></el-table-column>
        <el-table-column label="操作" width="300">
          <template #default="scope">
            <el-button size="small" text type="primary" @click="editChannel(scope.row)">修改</el-button>
            <el-button size="small" text type="danger" @click="deleteChannel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
          layout="total, prev, pager, next" :total="totalChannels"></el-pagination>
      </div>
      <el-dialog v-model="showEditChannel" title="修改推广位" width="500">
        <el-form :model="updateChannelFrom">
          <el-form-item label="名称">
            <el-input v-model="updateChannelFrom.name" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="showEditChannel = false">取消</el-button>
            <el-button type="primary" @click="confirmEditChannel">
              确认
            </el-button>
          </div>
        </template>
      </el-dialog>
      <el-dialog v-model="showDeleteChannel" title="确认删除" width="500">
        <span>确认要删除课程 {{ this.delChannel.name }} 吗？</span>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="showDeleteChannel = false">取消</el-button>
            <el-button type="primary" @click="confirmDeleteChannel()">
              确认
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      updateChannelFrom: {
        name: ''
      },
      Channels: [],
      currentPage: 1,
      pageSize: 10,
      totalChannels: 0,
      delChannel: {},
      eChannel: {},
      showEditChannel: false,
      showDeleteChannel: false,
    };
  },
  methods: {
    fetchChannels() {
      axios.get('/api/v1/channels', {
        params: {
          page: this.currentPage,
          limit: this.pageSize,
        },
      }).then((response) => {
        this.Channels = response.data.data;
        this.totalChannels = response.data.total;
      }).catch((error) => {
        this.$message.error('Failed to fetch Channels: ' + error.response.data.error);
      });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchChannels();
    },
    editChannel(Channel) {
      this.eChannel = Channel
      this.updateChannelFrom.name = Channel.name
      this.showEditChannel = true
    },
    deleteChannel(Channel) {
      this.delChannel = Channel
      this.showDeleteChannel = true
    },
    confirmEditChannel() {
      let that = this
      axios.put('/api/v1/channels',
        {
          username: that.eChannel.username,
          courseName: that.eChannel.courseName,
          oldName: that.eChannel.name,
          newName: that.updateChannelFrom.name
        }
      ).then(() => {
        that.$message.success('update Channel success');
        that.fetchChannels()
        that.showEditChannel = false
      }).catch((error) => {
        that.$message.error('Failed to update Channel: ' + error.response.data.error);
      });
    },
    confirmDeleteChannel() {
      let that = this
      let url = `/api/v1/channels/${that.delChannel.name}/courses/${that.delChannel.courseName}/users/${that.delChannel.username}`
      axios.delete(url,
        {
          username: that.delChannel.username,
          name: that.delChannel.name,
        }
      ).then(() => {
        that.$message.success('delete Channel success');
        that.fetchChannels()
        that.showDeleteChannel = false
      }).catch((error) => {
        that.$message.error('Failed to delete Channel: ' + error.response.data.error);
      });
    }
  },
  created() {
    this.fetchChannels();
  },
};
</script>

<style>
.order-data-container {
  padding: 20px;
}

.filter-section {
  margin-bottom: 20px;
}

.order-cards {
  display: flex;
  justify-content: space-between;
}

.el-card {
  width: 100%;
}

.el-row {
  display: flex;
  flex-wrap: wrap;
}

.el-col {
  flex: 1;
}

.data-card {
  width: 300px;
  height: 400px;
}
</style>